import Rating from '@mui/lab/Rating';
import React from 'react';
import './description-product.scss';
import { convertLink } from '../../../shared/utils/convertLongTextFieldLink';
const DescriptionProduct = props => {
  const [seePlus, setSeePlus] = React.useState(true);
  const [imgPresentation, setImgPresentation] = React.useState(0);
  const { productData, dataRating } = props;
  const {
    productPresentations,
    category,
    productName,
    description,
  } = productData;
  const cantCaracteres =
    description !== null ? description.description.length > 180 : 0;
  const handleChangeImgProduct = (e, index) => {
    setImgPresentation(index);
  };

  const { parts} = convertLink(description.description);

  const RenderProductPresentations = props => {
    const { presentations } = props;
    return presentations.map((val, index) => (
      <div
        key={index}
        className={`f-product-presentation-item ${
          imgPresentation === index ? 'f-item-img-active' : ''
        }`}
        onClick={e => handleChangeImgProduct(e, index)}
      >
        <div className="f-presentation-poper">{`X${val.quantity} ${
          val.measurementUnit != null ? val.measurementUnit.title : ''
        }`}</div>
        <img src={val.image.file.url}></img>
      </div>
    ));
  };
  const handleSeePlus = () => {
    setSeePlus(false);
  };
  return (
    <>
      <div className="f-product-internal-images">
        <img
          className="f-product-internal-img"
          src={productPresentations[imgPresentation].image.file.url}
        ></img>
        <div className="f-product-internal-items-img">
          {productPresentations.length > 1 ? (
            <RenderProductPresentations
              presentations={productPresentations}
            ></RenderProductPresentations>
          ) : null}
        </div>
      </div>
      <div className="f-product-internal-description">
        <div className="f-product-description-line-head"></div>
        <span className="f-product-description-category">
          {category.title.title}
        </span>
        <h1 className="f-product-description-title">
          {productName.productName}
        </h1>
        <div className="f-product-description-rating">
          <Rating
            className="f-product-description-rating-star"
            name="half-rating"
            value={Math.round(dataRating.productAvg)}
            readOnly={true}
          />
          <div className="f-product-description-rating-average">
            ({dataRating.totalQualifications})
          </div>
        </div>
        <p
          className={`f-product-description-description  ${
            seePlus ? 'f-elipsis' : ''
          }`}
        >
          {seePlus && cantCaracteres ? (
            <span className="f-product-see-plus" onClick={handleSeePlus}>
              ...más
            </span>
          ) : null}
{description !== null ? 
  parts.map((part, index) => 
    part.type === 'link' ? 
      <a key={index} href={part.url} className='f-product-description-link'>{part.text}</a> 
    : part.type === 'text' ?
      <span key={index}>{part.content}</span>
    : null
  ) 
  : ''
}
        </p>
      </div>
    </>
  );
};
export default DescriptionProduct;
