import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import RatingService from '../../../services/rating-service';
import ButtonsProduct from '../../molecules/buttons-product/buttons-product';
import CalificationProduct from '../../molecules/calification-product/calification-product';
import CarouselProduct from '../../molecules/carousel-product/carousel-product';
import CommenstProduct from '../../molecules/comments-product/comments-product';
import DescriptionProduct from '../../molecules/description-product/description-product';
import RelactedProduct from '../../molecules/relacted-product/relacted-product';
import BonusModal from '../bonus-modal/bonus-modal';
import {removeDoubleSlash} from '../../../shared/utils/urlUtil'
import { pushGtagEvent } from '../../../shared/utils/metricManagementUtil';
import './product-content.scss';

const ProductContent = props => {

  const [dataRating, setDataRating] = React.useState({});
  const [dataProducts, setDataProducts] = React.useState([]);
  const [isBonusModalVisible, setModalVisibility ] = React.useState(false)
  const { isMobile, productData, califications, setmodalActive } = props;
  const arrayCalifications = califications !== null ? califications : [];

  let productPresentations =
    productData.productPresentations !== null
      ? productData.productPresentations
      : [];

  useEffect(() => {
    RatingService.rating(productData.contentful_id).then(data => {
      setDataRating(data.data);
    });
    RatingService.getProductAvg().then(data => {
      setDataProducts(data.data);
    });
    if(productData.tieneModal){
      setModalVisibility(true);
    }
    gTagSelectItem(props.productData)
  }, []);

  const gTagSelectItem = (item) => {
    const {id, productName, commercialPartner, characteristics, productPresentations} = item
    pushGtagEvent({
      event: 'view_item',
      params: {
        ecommerce:{
          currency: 'COP',
          items: [{
            item_id: id,
            item_name: productName?.productName||'',
            coupon: 'Ninguno',
            discount: '0',
            affiliation: commercialPartner ? commercialPartner : '',
            item_brand: 'Familia',
            item_category: 'Productos',
            item_variant: characteristics ? characteristics[0].title : '',
            price: '0',
            currency: 'COP',
            quantity: '0',
          }],
        },
        value: '0',
        units: productPresentations ? `X${productPresentations[0].quantity}` : '',
        qualification: item.ratingValue ? `${item.ratingValue}` : '0',
        user_data: [],
        log_status: '',
      }
    })
  }

  const IconCharacteristic = props => {
    const { characteristics } = props;

    return characteristics.map((val, index) => (
      <div key={index} className="f-product-internal-features-item">
        <img src={val.image[0].file.url} alt="imagen caracteristica"></img>
        <label>{val.title.split('Caracteristica >')}</label>
      </div>
    ));
  };

  const redirectProducts = () => {
    navigate(`/productos/${productData.category.slug}`);
  };

  return (
    <>
    {isBonusModalVisible ? 
      <BonusModal 
      setModalVisibility={setModalVisibility} 
      image={productData.imagenModal ? (productData.imagenModal[isMobile ? 1 : 0].fluid) : null}
      description={productData.imagenModal ? (productData.imagenModal[isMobile ? 1 : 0].description) : null} /> : 
      null
    }
    <div className="f-product-internal">
      <div className="f-product-internal-header">
        <i className="fa fa-arrow-left" onClick={redirectProducts}></i>
        <div className="f-product-internal-header-title">Productos</div>
      </div>
      <CarouselProduct
        productPresentations={productPresentations}
      ></CarouselProduct>
      <div className="f-product-bread-crumbs">
        <a href={removeDoubleSlash(`/productos/${productData.category.slug}`)} className={"nostyle"}>
          <label>{`PRODUCTOS / ${productData.category.title.title} / `}</label>
        </a>
        {productData.productName.productName}
      </div>
      <div className="f-product-internal-container">
        <div className="f-product-internal-content">
          <DescriptionProduct
            productData={productData}
            dataRating={dataRating}
          ></DescriptionProduct>
          <div className="f-product-internal-features">
            <IconCharacteristic
              characteristics={productData.characteristics}
            ></IconCharacteristic>
          </div>
          <ButtonsProduct setmodalActive={setmodalActive} productData={productData}></ButtonsProduct>
          <div className="divider"></div>
          <CalificationProduct
            idProduct={productData.contentful_id}
            dataRating={dataRating}
            califications={arrayCalifications}
          ></CalificationProduct>
          <CommenstProduct
            isMobile={isMobile}
            idProduct={productData.contentful_id}
            califications={arrayCalifications}
          ></CommenstProduct>
          <RelactedProduct
            title={'Productos relacionados'}
            relacted={productData.relatedProducts}
            dataProducts={dataProducts}
          ></RelactedProduct>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProductContent;