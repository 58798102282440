import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Pagination from '../../molecules/pagination/pagination';
import './carousel-product.scss';

const CarouselProducts = props => {
  const { productPresentations } = props;
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [indexCarousel, setIndexCarousel] = React.useState(0);
  const [isVisibleImg, setisVisibleImg] = React.useState(false);
  const carouselImage = React.useRef(null);

  const handleChangeIndex = index => {
    setIndexCarousel(index);
  };
  const handleScroll = () => {
    let scrollTop = document.querySelector("html").scrollTop;
    let scrollTopMax = scrollTop + 200;
    if((scrollTop > carouselImage.current.offsetHeight) && !isVisibleImg){
      setisVisibleImg(true)
    }
    if((scrollTopMax <= carouselImage.current.offsetHeight) && isVisibleImg){
      setisVisibleImg(false)
    }
}

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  })

  const renderItemCarouselPresentaciones = sliders => {
    return sliders.map((val, index) => (
      <div key={index} className={`f-item-carousel`} >
        <div className="f-item-carousel-tag">{`X${val.quantity} ${
          val.measurementUnit !== null ? val.measurementUnit.shortName : ''
        }`}</div>
        <img
          className={`${isVisibleImg ? "f-item-carousel-img--hidden" : "f-item-carousel-img"}`}
          alt="imagen carousel home"
          src={val.image !== null ? val.image.file.url : ''}
          ref={carouselImage}
        />
      </div>
    ));
  };

  return (
    <div className="f-product-swipe-carousel">
      <AutoPlaySwipeableViews
        index={indexCarousel}
        onChangeIndex={handleChangeIndex}
        autoplay={false}
        className="f-product-swipe-content"
      >
        {renderItemCarouselPresentaciones(productPresentations)}
      </AutoPlaySwipeableViews>
      <Pagination
        type="buttons"
        dots={productPresentations.length}
        index={indexCarousel}
        onChangeIndex={handleChangeIndex}
      />
    </div>
  );
};

export default CarouselProducts;
