import React, { useState } from 'react';
import useMobileMode from '../../../hooks/useMobileMode';
import SEO from '../../../shared/components/seo';
import { siteUrl } from '../../../shared/utils/siteUrl';
import { stringUnicode } from '../../../shared/utils/utilities-seo';
import Layout from '../../organisms/layout/layout';
import ProductContent from '../../organisms/product-content/product-content';
import './product.scss';

const ProductTemplate = props => {

  const [modalActive, setmodalActive] = useState(null);
  const { isMobile } = useMobileMode();

  const productData = props.pageContext.data;
  const { productName, metaTitle, metaDescription, description } = productData;
  const califications = props.pageContext.califications;
  const menuActive = 2;

  const classes = {
    main: 'f-main-product',
    footer: 'f-footer-product',
  };

  let seoImg;
  let schema;

  if (productData) {
    try {
      seoImg = `https:${productData.image[0].file.url}`;      
    } catch (e) {
      console.error('error al obtener la imagen', e);
    }

    let ratingValue = productData.ratingValue !== null ? productData.ratingValue : 5;
    let reviewCount = productData.reviewCount !== null ? productData.reviewCount : 1;

    schema = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "url": siteUrl + props.path,
      "name": stringUnicode(productData.productName.productName),
      "description": stringUnicode(productData.metaDescription !== undefined ? productData.metaDescription.metaDescription : description ? description.description : ''),
      "image": seoImg,
      "sku": productData.id,
      "mpn": productData.id,
      "brand": {
        "@type": "Brand",
        "name": "Familia",
      },
      "review": {
        "@type": "Review",
        "author": {
          "@type": "Organization",
          "name": "Familia"
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": ratingValue.toString(),
        "reviewCount": reviewCount.toString(),
      }
    };
  }

  const schemaProductJSONLD = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schema),
  };

  return (
    <>
      <SEO
        slug={props.path}
        title={metaTitle || productName?.productName || ''}       
        description={metaDescription?.metaDescription || description?.description || ''}
        script={schemaProductJSONLD}
        image={seoImg}
      />
      {isMobile ? (
        <Layout
          menuActive={null}
          headerActive={false}
          footerActive={true}
          classes={classes}
          setmodalActive={setmodalActive}
          modalActive={modalActive}
          title={metaTitle}
          slug={props.path}
        >
          <ProductContent
            isMobile={isMobile}
            productData={productData}
            califications={califications}
            setmodalActive={setmodalActive}
          ></ProductContent>
        </Layout>
      ) : (
          <Layout menuActive={menuActive} footerActive={true} classes={classes}
            setmodalActive={setmodalActive}
            modalActive={modalActive}
            title={metaTitle}
            slug={props.path}    
          >
            <ProductContent
              productData={productData}
              califications={califications}
              setmodalActive={setmodalActive}
            ></ProductContent>
          </Layout>
        )}
    </>
  );
};

export default ProductTemplate;