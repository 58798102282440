import React from 'react';
import Rating from '@mui/lab/Rating';
import './item-comment-product.scss';

const ItemCommentProduct = props => {
  const [seePlus, setSeePlus] = React.useState(true);

  const { isModal, isMobile, dataComment, califications } = props;

  const arrayIcons = [
    'https://images.ctfassets.net/tcoigcjw85h2/2NSVwMuZL0rsdb6nclBKSV/ba2c7d53bc65e6ec6a015e3dbeff33eb/avatar_1.png',
    'https://images.ctfassets.net/tcoigcjw85h2/12UtiZiDDvQUGSN8yYJSSO/cdc38a06cc8fe7f9824e3e0a0ab98808/avatar_2.png',
    'https://images.ctfassets.net/tcoigcjw85h2/5aPcprrycDXZTmuLhV6wVf/08c5599a9a16aa00a97c2d5f57aa2152/avatar_3.png',
  ];
  const copyDataCommentFilter = []
  dataComment.reduce((acc,comment) => {

    let commentString = JSON.stringify(comment.comment)
    if(!acc.includes(commentString)){
      acc.push(commentString)
      copyDataCommentFilter.push(comment)
    }
    return acc;
  } , [])
  const dataCommentValidateAverage = copyDataCommentFilter.filter( dat => dat.average >= 3)

  let indiceArrayIcon = 0;

  return dataCommentValidateAverage.map((val, index) => {
    indiceArrayIcon = indiceArrayIcon + 1;
    indiceArrayIcon = indiceArrayIcon > 2 ? 0 : indiceArrayIcon;

    const date = val.commentDate.split(/[- : T]/); //YYYY MM DD HH MM SS
    // const date = new Date(val.commentDate);

    const fecha = date[2] + '/' + date[1] + '/' + date[0];

    const calification = califications.filter(
      value => parseInt(value.numberValue) === parseInt(val.average)
    );
    //console.log("🚀 ~ file: item-comment-product.jsx ~ line 31 ~ returndataComment.map ~ calification", calification)

    const nameCalification =
      califications.length > 0 ? calification[0].title : '';
    // console.log(nameCalification);

    const handleSeePlus = () => {
      setSeePlus(false);
    };

    const caracters = isMobile ? (isModal ? 115 : 90) : 500;

    return (
      <div key={index} className="f-product-comments-item-slider">
        <div className="f-product-comments-item-header">
          <img
            className="f-product-comments-item-img"
            src={arrayIcons[indiceArrayIcon]}
          ></img>
          <span className="f-product-comments-item-calification">
            {nameCalification}
          </span>
          <Rating
            className="f-product-comments-item-star"
            name="half-rating"
            value={parseInt(val.average)}
            readOnly
          />
          <span className="f-product-comments-item-date">{fecha}</span>
          <p
            className={`f-product-comments-item-content  ${
              seePlus ? 'f-elipsis' : ''
            }`}
          >
            {val.comment}

            {seePlus && val.comment.length > caracters ? (
              <span className="f-product-see-plus" onClick={handleSeePlus}>
                ...más
              </span>
            ) : null}
          </p>
        </div>
      </div>
    );
  });
};

export default ItemCommentProduct;
