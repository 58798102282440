import React, { useEffect } from 'react';
import RatingService from '../../../services/rating-service';
import Fab from '@mui/material/Fab';
import Modal from '@mui/material/Modal';
import ItemCommentProduct from '../../molecules/item-comment-product/item-comment-product';
import './comments-product.scss';

const CommentsProducts = props => {
  const { isMobile, idProduct, califications } = props;
  const [open, setOpen] = React.useState(false);
  const [dataComment, setDataComment] = React.useState([]);
  const [limitComment, setLimitComment] = React.useState(4);
  const [offsetComment, setOffsetComment] = React.useState(0);
  const [commentPlus, setCommentPlus] = React.useState(true);

  const handleOpenModal = () => {
    setOpen(true);
    setDataComment([]);
    handleCommentPlus(null, 5, -1);
  };

  const handleClose = () => {
    setOpen(false);
    setDataComment([]);
    handleCommentPlus(null, 4, -1);
  };

  const handleCommentPlus = (e, limit, offset) => {
    setOffsetComment(offset + 1);
    setLimitComment(limit);
  };

  useEffect(() => {
    RatingService.getComment(idProduct, limitComment, offsetComment).then(
      data => {
        open
          ? data.data.length > 0 && data.data.length > 4
            ? setCommentPlus(true)
            : setCommentPlus(false)
          : data.data.length > 0 && data.data.length > 2
          ? setCommentPlus(true)
          : setCommentPlus(false);

        setDataComment([...dataComment, ...data.data]);
      }
    );
  }, [limitComment, offsetComment, commentPlus]);

  return (
    <>
      {dataComment.length > 0 ? (
        <div className="f-product-comments">
          <h2 className="f-product-comments-title">
            Comentarios
            {dataComment.length > 3 ? (
              <label onClick={handleOpenModal}>Ver todas</label>
            ) : (
              ''
            )}
          </h2>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="f-modal-comments"
            open={open}
          >
            <div className="f-product-comments-modal">
              <div className="f-product-comments-modal-header">
                <i className="fa fa-arrow-left" onClick={handleClose}></i>
                <h2 className="f-product-comments-header-title">Comentarios</h2>
              </div>
              <div className="f-product-comments-modal-content">
                <ItemCommentProduct
                  isModal={true}
                  isMobile={isMobile}
                  dataComment={dataComment}
                  califications={califications}
                ></ItemCommentProduct>
                {commentPlus ? (
                  <Fab
                    variant="extended"
                    size="large"
                    aria-label="add"
                    className="f-product-comments-plus"
                    onClick={e => handleCommentPlus(e, 5, offsetComment)}
                  >
                    VER MÁS COMENTARIOS
                  </Fab>
                ) : null}
              </div>
            </div>
          </Modal>
          <div className="f-product-comments-content">
            <ItemCommentProduct
              isModal={false}
              isMobile={isMobile}
              dataComment={dataComment}
              califications={califications}
            ></ItemCommentProduct>
            {commentPlus ? (
              <Fab
                variant="extended"
                size="large"
                aria-label="add"
                className="f-product-comments-plus"
                onClick={e => handleCommentPlus(e, 3, offsetComment)}
              >
                VER MÁS COMENTARIOS
              </Fab>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CommentsProducts;
