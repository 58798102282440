
import React from 'react';
import Img from "gatsby-image"
import './bonus-modal.scss';

const BonusModal = (props) => {
    const { setModalVisibility, image, description } = props;
    return (
        <div className="f-bonus-modal-wrapper">
            <div className="f-bonus-modal-container">
                <span
                    className="f-close-modal-button"
                    onClick={() => setModalVisibility(false)}
                />
                {image ? <Img className="f-bonus-modal-image" fluid={image} alt={description} /> : null}
            </div>
        </div>
    )
}

export default BonusModal
