export const convertLink = (textToConvert) => {
    let regexLink = /\[([^\]]+)\]\(([^)]+)\)/g;
    let match;
    let parts = [];
    let lastIndex = 0;
  
    while ((match = regexLink.exec(textToConvert)) !== null) {

      if (match.index > lastIndex) {
        parts.push({
          type: 'text',
          content: textToConvert.slice(lastIndex, match.index),
        });
      }
  

      parts.push({
        type: 'link',
        text: match[1], 
        url: match[2],
      });
  
      lastIndex = regexLink.lastIndex;
    }
  
    if (lastIndex < textToConvert.length) {
      parts.push({
        type: 'text',
        content: textToConvert.slice(lastIndex),
      });
    }
  
  
    return {
      parts, 
    };
  };