import { withStyles } from '@mui/styles';
import Rating from '@mui/lab/Rating';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';

import RatingService from '../../../services/rating-service';

import './calification-product.scss';

const CalificationProduct = props => {
  const [dataCalification, setDataCalification] = React.useState([]);
  const { idProduct, dataRating, califications } = props;

  const calificationsArray = califications.sort(
    (a, b) => b.numberValue - a.numberValue
  );

  const grupoProduct =
    dataRating.productAvgGroupByCategory !== null &&
    dataRating.productAvgGroupByCategory !== undefined
      ? dataRating.productAvgGroupByCategory
      : [];

  const BorderLinearProgress = withStyles({
    root: {
      height: 15,
      borderRadius: 20,
    },
    bar: {
      borderRadius: 20,
    },
  })(LinearProgress);

  useEffect(() => {
    RatingService.getQualification(idProduct).then(data => {
      setDataCalification(data.data);
    });
  }, []);

  const CalificationGroup = props => {
    const { califications } = props;

    return califications.map((val, index) => (
      <div key={index} className="f-interna-item-calification">
        <span className="f-interna-item-title">
          {val.categoryName.split('Pregunta >')}
        </span>
        <div className="f-interna-value-star">
          <div>{parseFloat(val.avgQualification).toFixed(1)}</div>
          <Rating
            className="f-product-description-rating-star"
            name="half-rating"
            value={1}
            readOnly
            max={1}
          />
        </div>
      </div>
    ));
  };

  const RenderValuesDetails = props => {
    const { calification } = props;
    let sumaCalification = 0;
    dataCalification.map(val => (sumaCalification += val.numberVotes));

    const arrNumberVotes = dataCalification.filter(
      val => parseInt(val.qualification) === parseInt(calification)
    );
    const numberVotes =
      arrNumberVotes.length > 0 ? arrNumberVotes[0].numberVotes : 0;

    const valorProgress = (numberVotes * 100) / sumaCalification;

    return (
      <>
        <BorderLinearProgress
          className="f-interna-details-bar"
          variant="determinate"
          value={valorProgress}
        />
        <label className="f-interna-details-average">
          {numberVotes} votos / {sumaCalification}
        </label>
      </>
    );
  };

  const DetailsCalification = props => {
    const { califications } = props;
    // console.log(califications);

    const calificationsArray = califications.filter(
      val => parseInt(val.numberValue) !== 0
    );

    return calificationsArray.map((val, index) => (
      <div key={index} className="f-interna-details-values">
        <label className="f-interna-details-number">{val.numberValue}</label>
        <RenderValuesDetails
          calification={val.numberValue}
        ></RenderValuesDetails>
      </div>
    ));
  };

  return (
    <>
      {grupoProduct.length > 0 ? (
        <div className="f-interna-calification">
          <h2 className="f-interna-title">Calificación</h2>
          <div className="f-interna-content">
            <div className="f-interna-values">
              <h3 className="f-interna-values-title">Items calificados</h3>
              <CalificationGroup
                califications={grupoProduct}
              ></CalificationGroup>
            </div>
            <div className="f-interna-details">
              <h3 className="f-interna-details-title">Detalle</h3>
              <DetailsCalification
                califications={calificationsArray}
              ></DetailsCalification>
            </div>
            <div className="f-interna-general">
              <div className="f-interna-img-calification"></div>
              <div className="f-interna-general-average">
                {parseFloat(dataRating.productAvg).toFixed(1)}
              </div>
              <Rating
                className="f-product-description-rating-star"
                name="half-rating"
                value={Math.round(dataRating.productAvg)}
                readOnly
              />
              <div className="f-interna-general-total">
                ({dataRating.totalQualifications})
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CalificationProduct;
